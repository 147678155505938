import OpenAPIClientAxios from 'openapi-client-axios'
import {getUserData} from "../utility/Utils"
import jwtDefaultConfig from "../@core/auth/jwt/jwtDefaultConfig"
import {toast} from "react-toastify"
import {ErrorToast} from "../views/extensions/toastify/ToastTypes"
import { logoutRoute } from '../router/enums'

const requestInterceptor = (withoutToken) => {
  return [
    config => {
      config.headers.Accept = 'application/json'
      
      if (!withoutToken) {
        // Get user data from local storage
        const userData = getUserData()
        // If token is present add it to request's Authorization Header
        if (userData && !config.headers.Authorization) {
          config.headers.Authorization = `${jwtDefaultConfig.tokenType} ${userData.accessToken}`
        }
      }

      return config
    },
    error => Promise.reject(error)
  ]
}

const responseInterceptor = [
  response => response,
  error => {
    // ** const { config, response: { status } } = error
    const {response} = error

    // Unexpected error, show toast
    if (response && response.status === 401) {
      const userData = getUserData()
      if (userData?.accessToken) { // Token expired so let's reload to login
        window.location.href = logoutRoute
        return
      } else if (
        response.data.message !== 'Invalid credentials.' &&
        response.data.message !== 'You are not part of any company.'
      ) { // Invalid credentials is handled by Login.js
        toast.error(
          <ErrorToast>{response.data.message}</ErrorToast>,
          {hideProgressBar: true}
        )
      }
    }
    return Promise.reject(error)
  }
]

function createApi(entrypoint) {
  // Initialize client API
  const api = new OpenAPIClientAxios({
    definition: entrypoint
  })

  return {
    getClient: async (params) => {
      const client = await api.getClient()
      if (!client.interceptors.request.handlers.length) {
        client.interceptors.request.use(...requestInterceptor(params))
      }
      if (!client.interceptors.response.handlers.length) {
        client.interceptors.response.use(...responseInterceptor)
      }
      return client
    }
  }
}

export default createApi
